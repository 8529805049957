<template>
  <div class="audioBookDetails oepnX">
    <div class="head">
      <div class="headBar" v-if="atlasData.title">
        <div class="leftIcon" @click="$router.go(-1), this.audioPause()">
          <svg-icon class="navArrow" iconClass="navArrow" />
        </div>
        <span class="worksTitle">{{ atlasData.title }}</span>
        <!-- 收藏 -->
        <div class="btnGroup">
          <div
            class="collectionBtn"
            v-if="atlasData && !atlasData.mediaStatus.hasCollected"
            @click="collectionWorks"
          >
            <svg-icon class="collect" iconClass="isnovelCollect" />
          </div>
          <div
            class="collectionBtn"
            v-else-if="atlasData"
            @click="cancelCollectionWorks"
          >
            <!--                <img src="@/assets/png/hasnovelCollect.png" />-->
            <svg-icon class="collect" iconClass="hasnovelCollect" />
          </div>
        </div>
      </div>
    </div>
    <PullRefresh v-if="!isLoading" :disabled="true" :is-higeht-max="true">
      <div class="content" v-if="!isLoading && atlasData">
        <div class="bgBox">
          <ImgDecypt
            class="headBg"
            :src="atlasData.verticalCover"
            :key="atlasData.verticalCover"
          ></ImgDecypt>
          <div class="contentInfo">
            <div class="worksContent">
              <audio
                v-if="this.currentEpisode.audioUrl"
                ref="audio"
                :src="audioApi + '/' + this.currentEpisode.audioUrl"
                @timeupdate="onTimeUpdate"
                @loadedmetadata="onLoadedmetadata"
                @ended="onEnded"
                style="display: none"
              ></audio>
              <div class="action">
                <div @click="playPrevious()">
                  <svg-icon class="palyIcon" iconClass="lastEpisode" />
                </div>
                <div @click="onSeek(-15)">
                  <svg-icon class="palyIcon" iconClass="forward" />
                </div>
                <div @click="isPlay = true" v-show="!isPlay">
                  <svg-icon class="play" iconClass="pause" />
                </div>
                <div @click="isPlay = false" v-show="isPlay">
                  <svg-icon class="play" iconClass="play" />
                </div>
                <div @click="onSeek(15)">
                  <svg-icon class="palyIcon" iconClass="backward" />
                </div>
                <div @click="playNext()">
                  <svg-icon class="palyIcon" iconClass="nextEpisode" />
                </div>
              </div>
              <!--   进度条    -->
              <div class="controlBar flex-between">
                <div @click="visibleRate = true">
                  <svg-icon iconClass="audio_time_rate"></svg-icon>
                </div>
                <div class="currTime">{{ currentTime | videotime }}</div>
                <van-slider
                  class="progress"
                  active-color="#B09FD9"
                  v-model="currentProgress"
                  @change="onChange"
                >
                  <template #button>
                    <svg-icon class="custom-button" iconClass="progressBar" />
                  </template>
                </van-slider>
                <div class="totalTime">{{ duration | videotime }}</div>
                <div @click="visibleTime = true">
                  <svg-icon iconClass="audio_time_time"></svg-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="directory">
          <div class="directoryBar">
            <div class="directoryBarTitle">
              <span>选集</span>
            </div>
            <div class="directoryBtn" @click="directoryShow = true">
              <span v-if="kind == 4">全{{ atlasData.contents.length }}章</span>
              <span v-else>全{{ atlasData.contents.length }}集</span>
              <!--                       <img src="@/assets/png/rightDown.png">-->
            </div>
          </div>
          <div class="anthology oepnX" id="anthologyID">
            <div
              :class="`anthologyItem-${index}`"
              v-for="(item, index) in atlasData.contents"
              @click="anthology(item, index)"
              :ref="`item-${index}`"
              :key="index"
            >
              <div
                class="anthologyItem"
                :class="{ activeItem: activeIndex == index }"
              >
                <div
                  class="anthologyTxt"
                  :class="{ activeItem: activeIndex == index }"
                >
                  <span>
                    {{ index + 1 }}
                  </span>
                </div>
                <!-- vip -->
                <div class="vipLabel" v-if="item.listenPermission == 0">
                  <!--                 <span>VIP</span>-->
                  <img src="@/assets/png/vipIcon.png" alt="" />
                </div>

                <!-- 金豆 -->
                <div class="goldenLabel" v-if="item.listenPermission == 1">
                  <!--                 <span>金豆</span>-->
                  <img src="@/assets/png/GoldenIcon.png" alt="" />
                </div>

                <!-- 免费 -->
                <div class="freeLabel" v-if="item.listenPermission == 2">
                  <!--                 <span>免费</span>-->
                  <img src="@/assets/png/freeIcon.png" alt="" />
                </div>
                <!-- <div class="activePlay" v-if="activeIndex == index">
                            <img src="@/assets/gif/activePlay.gif">
                        </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- 同类作品 -->
        <div class="recommendVideo">
          <div class="recommendTitle">
            <span>探索发现</span>
          </div>
          <div>
            <VerticleThreeList
              :videoList="randomList"
              :isAcg="true"
            ></VerticleThreeList>
          </div>
        </div>

        <div class="separate"></div>

        <div class="comments">
          <div class="commentsContent">
            <div class="commentsHead">
              <span>评论</span>
              <!--       <span class="comTotle">{{ msgTotle }}</span>-->
            </div>
            <div class="qiuckSearchBox flex-aic" v-if="quickSearchObj.title">
              <span>大家都在搜索：</span>
              <van-notice-bar
                v-if="quickSearchObj.title"
                @click.stop="jumpAdv(quickSearchObj)"
                color="#F92B6C"
                background="transparent"
                scrollable
                :text="quickSearchObj.title"
              />
            </div>
            <div class="commentsContentList" v-if="commentList.length > 0">
              <div
                class="commentBox"
                v-for="item in commentList"
                :key="item.id"
              >
                <div class="commentInfo">
                  <ImgDecypt
                    class="avatar"
                    round
                    :src="item.userPortrait"
                    :key="item.userPortrait"
                  />
                  <div class="info">
                    <div class="name">
                      <span>{{ item.userName }}</span>
                      <img
                        class="hotImg"
                        v-if="item.isHotComment"
                        src="@/assets/png/isHotComment.png"
                      />
                      <img
                        class="hotImg"
                        v-if="item.isTop"
                        src="@/assets/png/commentTop.png"
                      />
                      <!--                     <svg-icon-->
                      <!--                        :iconClass="-->
                      <!--                          item.gender == 'male' ? 'maleMsg' : 'female'-->
                      <!--                        "-->
                      <!--                      ></svg-icon>-->
                    </div>
                    <div class="text msgContent" v-if="!item.isDelete">
                      <div style="width: 100%">
                        <CommentJump
                          class="commit_content"
                          :text="item.content"
                          :keyword="item.searchKeyword"
                          :linkType="item.linkType"
                          :linkStr="item.linkStr"
                        />
                      </div>
                    </div>
                    <div class="text" style="color: rgb(153, 153, 153)" v-else>
                      内容已被删除
                    </div>
                    <div class="address" v-if="!item.isTop && !item.isOfficial">
                      <div class="createdAt">
                        <span>{{ formatDate(item.createdAt) }}</span>
                      </div>

                      <div class="btnBox" v-if="!item.linkType">
                        <span @click.stop="reply(item, null)">
                          <svg-icon
                            class="community_info"
                            iconClass="community_info"
                          />
                        </span>

                        <span @click.stop="likeComment(item)">
                          <svg-icon
                            class="like icon"
                            v-if="item.isLike"
                            iconClass="postLikeedIcon"
                          />
                          <svg-icon
                            class="like icon"
                            v-else
                            iconClass="postLikeIcon"
                          />
                        </span>
                      </div>
                    </div>
                    <!--                    <div v-else>-->
                    <!--                      <span style="color: rgb(153, 153, 153); font-size: 12px"-->
                    <!--                        >&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;此评论系统生成 无法回复</span-->
                    <!--                      >-->
                    <!--                    </div>-->

                    <div class="commentBox" style="border-bottom: none">
                      <div
                        class="commentInfo"
                        v-if="item.Info && item.Info.length > 0"
                        style="margin-bottom: 10px"
                      >
                        <ImgDecypt
                          class="avatar"
                          round
                          :src="item.Info[0].userPortrait"
                          :key="item.Info[0].userPortrait"
                        />
                        <div class="childInfo">
                          <div class="name">
                            <span>{{ item.Info[0].userName }}</span>
                            <!--                            <svg-icon-->
                            <!--                              :iconClass="-->
                            <!--                                item.Info[0].gender == 'male'-->
                            <!--                                  ? 'male'-->
                            <!--                                  : 'female'-->
                            <!--                              "-->
                            <!--                            ></svg-icon>-->
                            <svg-icon
                              v-if="
                                item.Info[0].toUserID != 0 &&
                                item.Info[0].toUserID != null
                              "
                              class="rightCommIcon"
                              iconClass="rightCommIcon"
                            ></svg-icon>
                            <span v-if="item.Info[0].toUserID">{{
                              item.Info[0].toUserName
                            }}</span>
                          </div>
                          <div
                            class="text msgContent"
                            v-if="!item.Info[0].isDelete"
                          >
                            <div style="width: 100%">
                              <CommentJump
                                class="commit_content"
                                :text="item.Info[0].content"
                                :keyword="item.Info[0].searchKeyword"
                                :linkType="item.Info[0].linkType"
                                :linkStr="item.Info[0].linkStr"
                              />
                            </div>
                          </div>
                          <div
                            class="text"
                            style="color: rgb(153, 153, 153)"
                            v-else
                          >
                            内容已被删除
                          </div>
                          <div class="address">
                            <div class="createdAt">
                              <span>{{
                                formatDate(item.Info[0].createdAt)
                              }}</span>
                            </div>

                            <div class="btnBox" v-if="!item.Info[0].linkType">
                              <span @click.stop="reply(item.Info[0], item)">
                                <svg-icon
                                  class="community_info"
                                  iconClass="community_info"
                                />
                              </span>

                              <span @click.stop="likeComment(item.Info[0])">
                                <svg-icon
                                  class="like icon"
                                  v-if="item.Info[0].isLike"
                                  iconClass="postLikeedIcon"
                                />
                                <svg-icon
                                  class="like icon"
                                  v-else
                                  iconClass="postLikeIcon"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="item.isShow">
                        <div
                          class="commentInfo"
                          v-for="subComment in item.Info.slice(1)"
                          :key="subComment.id"
                          style="margin-bottom: 10px"
                        >
                          <ImgDecypt
                            class="avatar"
                            round
                            :src="subComment.userPortrait"
                            :key="item.userPortrait"
                          />
                          <div class="childInfo">
                            <div class="name">
                              <span>{{ subComment.userName }}</span>
                              <!--                              <svg-icon-->
                              <!--                                :iconClass="-->
                              <!--                                  subComment.gender == 'male'-->
                              <!--                                    ? 'male'-->
                              <!--                                    : 'female'-->
                              <!--                                "-->
                              <!--                              ></svg-icon>-->
                              <svg-icon
                                v-if="
                                  subComment.toUserID != 0 &&
                                  subComment.toUserID != null
                                "
                                class="rightCommIcon"
                                iconClass="rightCommIcon"
                              ></svg-icon>
                              <span
                                v-if="
                                  subComment.toUserID != 0 &&
                                  subComment.toUserID != null
                                "
                                >{{ subComment.toUserName }}</span
                              >
                            </div>
                            <div
                              class="text msgContent"
                              v-if="!subComment.isDelete"
                            >
                              <div style="width: 100%">
                                <CommentJump
                                  class="commit_content"
                                  :text="subComment.content"
                                  :keyword="subComment.searchKeyword"
                                  :linkType="subComment.linkType"
                                  :linkStr="subComment.linkStr"
                                />
                              </div>
                            </div>
                            <div
                              class="text"
                              style="color: rgb(153, 153, 153)"
                              v-else
                            >
                              内容已被删除
                            </div>
                            <div class="address">
                              <div class="createdAt">
                                <span>{{
                                  formatDate(subComment.createdAt)
                                }}</span>
                              </div>

                              <div class="btnBox" v-if="!subComment.linkType">
                                <span @click.stop="reply(subComment, item)">
                                  <svg-icon
                                    class="community_info"
                                    iconClass="community_info"
                                  />
                                </span>

                                <span @click.stop="likeComment(subComment)">
                                  <svg-icon
                                    class="like icon"
                                    v-if="subComment.isLike"
                                    iconClass="postLikeedIcon"
                                  />
                                  <svg-icon
                                    class="like icon"
                                    v-else
                                    iconClass="postLikeIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Loading
                        class="sec-loading"
                        v-if="
                          commentParams.currentFirstItem.id == item.id &&
                          commentParams.currentLoading
                        "
                      />
                      <div
                        class="load-more"
                        v-else-if="
                          !commentParams.currentLoading &&
                          commentParams.currentFirstItem.id == item.id &&
                          commentParams.currentHasNext
                        "
                        @click.stop="loadMoreSubComment(item)"
                      >
                        — 点击加载更多评论
                      </div>
                    </div>
                  </div>
                  <img
                    class="godMsg"
                    v-if="item.isGodComment"
                    src="@/assets/png/godMsg.png"
                  />
                </div>
                <div
                  class="expand-box"
                  @click.stop="onShow(item)"
                  v-if="item.commCount && item.commCount > 1"
                >
                  {{
                    item.isShow ? "— 收起" : `— 展开${item.commCount - 1}条回复`
                  }}
                  <svg-icon
                    iconClass="expand"
                    :class="[
                      { 'rotate-target': item.isShow },
                      { 'rotate-burden-target': !item.isShow },
                    ]"
                  />
                </div>
              </div>
            </div>
            <div class="nodata" v-else>空空如也～</div>
          </div>
        </div>
      </div>
    </PullRefresh>
    <Loading v-else />

    <div class="tabBars">
      <div class="comments">
        <van-field
          class="Inputs"
          ref="commentInputBoxRef"
          v-model="commentParams.content"
          :border="false"
          :placeholder="placeholder"
          @blur="changBlur"
        />
        <div
          class="sendBtn"
          :class="{ activeBtn: msgVal }"
          @mousedown="handleMousedown"
        >
          <img src="@/assets/png/sendMsg.png" />
        </div>
      </div>
    </div>
    <!-- 简介弹窗 -->
    <van-popup
      v-model="introductionShow"
      position="bottom"
      :style="{ height: '529px', background: 'rgb(255,255,255)' }"
    >
      <div class="introductionContent" v-if="atlasData">
        <div class="introductionBar">
          <div class="title">
            <span>简介</span>
          </div>
          <div class="btn" @click="introductionShow = false">
            <img src="@/assets/png/popupClose.png" />
          </div>
        </div>
        <div class="introductionTitle" v-if="atlasData.title">
          <span>{{ atlasData.title }}</span>
        </div>
        <div class="introductionTag">
          <span v-for="(item, index) in atlasData.tagNames" :key="index">
            {{ item }}
          </span>
        </div>
        <div class="introductionDetails">
          <span>{{ atlasData.summary }}</span>
        </div>
      </div>
    </van-popup>
    <!-- 倍速弹窗 -->
    <van-popup
      v-model="visibleRate"
      round
      position="bottom"
      @close="onRateClose"
    >
      <div class="rate-popup">
        <!--    <div class="title">播放速度</div>-->
        <van-picker
          class="picker"
          v-model="rateValue"
          :columns="rateList"
          show-toolbar
          @change="onPickerChange"
        />
      </div>
    </van-popup>
    <!-- 定时关闭 -->
    <van-popup
      v-model="visibleTime"
      round
      position="bottom"
      @close="onTimeClose"
    >
      <div class="time-popup">
        <div v-show="timeVisibleOption" class="option-box">
          <div class="header flex-between">
            <div class="title">定时关闭</div>
            <div>{{ timeText }}</div>
          </div>
          <div class="top">
            <div class="list flex-between">
              <span
                class="item"
                :class="{ active: timeNum === num }"
                v-for="num of timeOption"
                :key="num"
                @click="num === '自定义' ? customTime() : setTime(num)"
              >
                {{ num }}
              </span>
            </div>
          </div>
          <div class="bottom">
            <van-checkbox v-model="timeChecked" @change="handleCheckboxChange"
              >播完整部再停止播放</van-checkbox
            >
          </div>
        </div>
        <div v-show="!timeVisibleOption" class="picker-box">
          <div class="btns flex-between">
            <div class="btn cancel" @click="timeVisibleOption = true">取消</div>
            <div class="btn confirm" @click="onTimePick()">完成</div>
          </div>
          <div class="picker">
            <van-picker
              :columns="columns"
              :visible-item-count="5"
              @change="change"
            />
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 目录弹窗 -->
    <van-popup
      v-model="directoryShow"
      position="bottom"
      :style="{ height: '511px', background: '#F5F5F5' }"
    >
      <div class="directoryContent" v-if="atlasData">
        <div class="directoryBar">
          <div class="title">
            <span v-if="kind == 7"
              >目录（共{{ atlasData.contents2.length }}章）</span
            >
          </div>
          <div class="btn" @click="chapterSort">
            <span v-if="sorting">倒序</span>
            <span v-else>升序</span>
          </div>
          <!-- <div class="btn" @click="directoryShow = false">
       <img src="@/assets/png/popupClose.png" />
     </div> -->
        </div>
        <div class="anthologyContent">
          <div
            class="box"
            v-for="(item, index) in atlasData.contents2"
            @click="
              anthology(
                item,
                sorting ? index : atlasData.contents2.length - index - 1
              )
            "
            :key="index"
          >
            <div
              class="anthologyItem"
              :class="{ activeItem: activeIndex2 == index }"
            >
              <div
                class="anthologyTxt"
                :class="{ activeItem: activeIndex2 == index }"
              >
                <!-- vip -->
                <div class="vipLabel" v-if="item.listenPermission == 0">
                  <img src="@/assets/png/vipIcon.png" alt="" />
                </div>
                <!-- 金豆 -->
                <div class="goldenLabel" v-if="item.listenPermission == 1">
                  <img src="@/assets/png/GoldenIcon.png" alt="" />
                </div>
                <!-- 免费 -->
                <div class="freeLabel" v-if="item.listenPermission == 2">
                  <img src="@/assets/png/freeIcon.png" alt="" />
                </div>
                <span>
                  {{ item.episodeNumber }}
                </span>
              </div>
              <div class="isWatch">
                {{
                  item.mediaStatus.hasRead === 0
                    ? "收听中"
                    : item.mediaStatus.hasRead
                    ? "已收听"
                    : "未收听"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import TextOverflow from "@/components/TextOverflow";
import { Toast } from "vant";
import {
  sendComment,
  sendV2Comment,
  thumbsDownComment,
  thumbsUpComment,
} from "@/api/mine";
import {
  doCollect,
  douUcollect,
  queryMediaInfoList,
  queryRandomInfoList,
} from "@/api/video";
import ImgDecypt from "@/components/ImgDecypt";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import PullRefresh from "@/components/PullRefresh";
import CommentJump from "@/components/CommentJump";
import { setSessionItem } from "@/utils/longStorage";
import { addVideoHistory, formatDate, queryHistoryId } from "@/utils";
import { getBeijinTime } from "@/utils";
import { queryCommentListlever2 } from "@/api/user";
import { formatTime, videotime } from "@/assets/js/filters";
import { mapGetters } from "vuex";

import store from "@/store";
import { queryCommentList } from "@/api/user";
import { jumpAdv } from "@/utils/getConfig";
export default {
  name: "AudioBookDetails",
  components: {
    ImgDecypt,
    Loading,
    VerticleThreeList,
    PullRefresh,
    CommentJump,
    // TextOverflow
  },
  computed: {
    ...mapGetters({
      buyStatus: "buyStatus",
      audioApi: "audioApi",
    }),
    timeText() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  data() {
    return {
      maxEpisode: 0, // 用于存储最大集数
      mediaID: null,
      kind: null,
      atlasData: null,
      introductionShow: false,
      directoryShow: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      randomList: [],
      finished: false,
      isNoData: false,
      error: false,
      activeIndex: 0,
      activeIndex2: 0,
      commentList: [],
      pageNumber: 1,
      pageSize: 10,
      isShowInput: false,
      msgVal: "",
      placeholder: "我怀疑你也想开车",
      cid: undefined,
      userId: undefined,
      level: 1,
      msgTotle: 0,
      price: 0,
      sorting: true,
      currMsgItem: "",
      commentParams: {
        objID: "", // 帖子ID,
        level: 1, // 评论级别，1: 一级评论;2: 二级评论
        content: "", // 评论内容
        cid: "", // 一级评论的ID
        userID: "", // 一级评论的用户ID
        currentFirstItem: {}, // 当前一级评论对象
        currentHasNext: false, // 当前评论是否还有下一页数据
        currentLoading: false, // 当前二级评论的loading
        currentShowInputId: "", // 当前显示的输入框id
        rid: "",
        toUserID: "",
      }, // 评论参数
      secPageNumber: 1, // 二级评论页码
      secPageSize: 10, // 二级评论一页的数量
      currentProgress: 0, // 当前播放进度条
      sourceUrl: "", // 选线
      isPlay: false, // 默认暂停
      initPlay: false, // 初始化播放
      currentTime: 0, // 当前时间
      dragState: false, // 进度条是否拖动
      duration: 0, // 总时长
      visibleRate: false, // 倍速弹窗
      rateList: [
        { text: "1.0倍", value: 1 },
        { text: "1.25倍", value: 1.25 },
        { text: "1.5倍", value: 1.5 },
        { text: "2倍", value: 2 },
      ],
      rateValue: [1],
      visibleTime: false, // 定时关闭弹窗
      timeNum: 0,
      timeOption: ["10", "20", "30", "45", "60", "自定义"],
      timeChecked: false,
      timeVisibleOption: true,
      timeValues: [0, 0],
      remainingTime: 0,
      columns: [
        {
          values: [],
          defaultIndex: 0,
        },
        {
          values: [],
          defaultIndex: 0,
        },
      ],
      currentEpisode: {}, // 当前集数列表
      currentItem: 0, // 当前集数
      quickSearchObj: {}, // 大家都在搜对象
    };
  },
  beforeDestroy() {
    this.atlasData.historyIndex = this.currentItem;
    addVideoHistory(this.atlasData);
    clearInterval(this.timer);
  },
  mounted() {
    this.isLoading = true;
    let currentWorks = this.$store.getters.currentWorks;
    console.log(currentWorks, "dadad");

    let query = this.$route.query;
    if (query) {
      this.mediaID = query.id;
      this.kind = query.kind;
      this.price = query.price ? Number(query.price) : 0;
      let itemIndex = Number(query.itemIndex);
      this.currentItem = itemIndex;
      this.activeIndex = itemIndex;
      this.activeIndex2 = itemIndex;
      if (!itemIndex) {
        itemIndex = 0;
      }

      this.getMediaInfoList();
      this.getRandomInfoList();
      this.getCommentList();
    }
    this.sourceUrl = this.$store.getters.videoRoadLine.url;
    this.audioPause();
    window.jumpKeywords = this.jumpKeywords;
  },
  activated() {
    let query = this.$route.query;
    if (query) {
      this.resetData(query);
    }
    this.setActiveIndex();
  },
  created() {
    this.initializeTimeList();
  },
  deactivated() {
    this.atlasData.historyIndex = this.activeIndex;
    addVideoHistory(this.atlasData);
  },
  watch: {
    $route(to, from) {
      console.log(to.query);
      const { id } = to.query;
      if (id) {
        this.resetData(to.query);
      }
    },
    isPlay(newVal) {
      console.log(newVal, "播放状态");
      // if (newVal) this.$refs.audio.play();
      // else this.$refs.audio.pause();
      if (newVal) {
        this.$nextTick(() => {
          const currentItem = this.currentEpisode || this.atlasData.content[0];
          this.playEpisode(currentItem, this.currentItem);
        });
      } else {
        this.$refs.audio.pause();
      }
    },
  },
  methods: {
    formatDate,
    jumpKeywords(str) {
      const strArr = str.split(",");
      const link = strArr[0];
      const id = strArr[1];
      let obj = {
        link: link,
        id: id,
      };
      jumpAdv(obj);
    },
    handleCheckboxChange() {
      if (this.timeChecked) {
        this.startTimer(this.duration);
      } else {
        clearInterval(this.timer);
      }
    },
    audioPlay() {
      const audio = this.$refs.audio;
      this.isPlay = true;
      audio.play();
    },
    audioPause() {
      const audio = this.$refs.audio;
      this.isPlay = false;
      audio.pause();
    },
    playPrevious() {
      console.log(this.currentEpisode);
      if (this.currentItem == 0) {
        this.$toast({
          message: "已经是第一篇",
          position: "top",
        });
      } else {
        let itemIndex = Number(this.currentItem) - 1;
        let itemContents = this.atlasData.contents[itemIndex];
        this.anthology(itemContents, itemIndex);
      }
    },
    playNext() {
      if (this.currentItem == this.atlasData.contents.length - 1) {
        this.$toast({
          message: "已经是最后一篇",
          position: "top",
        });
      } else {
        let itemIndex = Number(this.currentItem) + 1;
        let itemContents = this.atlasData.contents[itemIndex];
        this.anthology(itemContents, itemIndex);
      }
    },
    onChange(progress) {
      const audio = this.$refs.audio;
      const { duration } = audio;
      const changeCurrentTime = (progress * duration) / 100;
      audio.currentTime = changeCurrentTime;
    },
    onTimeUpdate() {
      const audio = this.$refs.audio;
      const { currentTime, duration } = audio;
      const progress = (currentTime / duration) * 100;
      this.currentProgress = progress;
      this.currentTime = parseInt(currentTime.toString());
      this.duration = parseInt(duration.toString());
    },
    onLoadedmetadata() {
      const audio = this.$refs.audio;
      const { duration } = audio;
      if (audio === null) return;
      this.duration = parseInt(duration.toString());
      try {
        audio.play();
        this.isPlay = !audio.paused;
      } catch (e) {
        console.log(e);
      }
    },
    onEnded() {
      // const audio = this.$refs.audio;
      if (this.timeChecked && this.remainingTime) {
        this.audioPause();
        clearInterval(this.timer);
      }
      if (this.remainingTime) {
        this.playNext();
      } else {
        this.audioPause();
        clearInterval(this.timer);
      }
    },
    // 快进
    onSeek(num) {
      this.$refs.audio.currentTime = this.currentTime + num;
    },
    // 倍速弹窗
    onPickerChange(picker, value, index) {
      this.$refs.audio.playbackRate = this.rateList[index].value;
    },
    onRateClose() {
      this.visibleRate = false;
    },
    // 定时关闭
    audioTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
    setTime(num) {
      this.timeNum = num;
      this.remainingTime = num * 60;
      this.startTimer(this.remainingTime);
    },
    onTimePick() {
      const [hours, minutes] = this.timeValues;
      console.log(this.timeValues);
      const totalSeconds = hours * 3600 + minutes * 60;
      this.startTimer(totalSeconds);
      this.timeVisibleOption = true;
      this.visibleTime = false;
    },
    startTimer(seconds) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.remainingTime = seconds;
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          console.log(this.remainingTime);
          this.remainingTime--;
        } else {
          clearInterval(this.timer);
          this.audioPause();
        }
      }, 1000);
    },
    onTimeClose() {
      this.visibleTime = false;
    },
    change(picker, value, index) {
      console.log(picker, value, index);
      this.timeValues = value;
    },
    customTime() {
      this.timeVisibleOption = false;
      this.initializeTimeList();
    },
    initializeTimeList() {
      this.columns = [
        {
          values: [],
          defaultIndex: 0,
        },
        {
          values: [],
          defaultIndex: 0,
        },
      ];
      for (let i = 0; i <= 60; i++) {
        if (i <= 24) {
          this.columns[0].values.push(i.toString().padStart(2, "0"));
        }
        this.columns[1].values.push(i.toString().padStart(2, "0"));
      }
      this.timeValues = [this.columns[0].values[0], this.columns[1].values[0]];
    },
    resetData(query) {
      this.audioPause();
      this.atlasData = null;
      this.mediaID = query.id;
      this.kind = query.kind;
      this.price = query.price ? Number(query.price) : 0;
      let itemIndex = Number(query.itemIndex);
      this.currentItem = itemIndex;
      this.activeIndex = itemIndex;
      this.activeIndex2 = itemIndex;
      if (!itemIndex) {
        itemIndex = 0;
      }
      this.isLoading = true;
      this.getMediaInfoList();
      this.getRandomInfoList();
      this.commentList = [];
      this.getCommentList("refresh");
    },
    // 缓存设置级数
    async setActiveIndex() {
      this.directoryShow = false;
      let historyPostInfo = await queryHistoryId(this.atlasData.id);
      this.activeIndex = historyPostInfo.historyIndex || 0;
      this.activeIndex2 = historyPostInfo.historyIndex || 0;
      const element = document.getElementById("anthologyID");
      let targetElement = document.getElementsByClassName(
        `anthologyItem-${this.activeIndex}`
      )[0]; // 选择目标元素
      let rect = targetElement.getBoundingClientRect().left;
      let elementWidth = targetElement.offsetWidth;
      let parentWidth = element.offsetWidth;
      element.scrollLeft = rect + elementWidth / 2 - parentWidth / 2;
      // element.scrollLeft = rect;
    },
    scrolls(index) {
      if (index) {
        this.$nextTick(() => {
          let target = this.$refs[`item-${index}`][0];
          let parent = target.parentNode;
          let parentHalfWidth = parent.offsetWidth / 2;
          let targetHalfWidth = target.offsetWidth / 2;
          parent.scrollLeft =
            target.offsetLeft - parentHalfWidth + targetHalfWidth;
        });
      }
    },
    chapterSort() {
      //章节排序
      this.sorting = !this.sorting;
      this.atlasData.contents2.reverse();
      this.activeIndex2 =
        this.atlasData.contents2.length - 1 - this.activeIndex2;
      console.log(this.atlasData.contents);
    },
    async onShow(comment) {
      console.log(comment);
      if (comment.isShow) {
        this.$set(comment, "isShow", false);
      } else {
        this.commentParams.currentHasNext = true; // 初始化当前评论默认有下一页
        this.commentParams.currentFirstItem = comment;
        this.commentParams.currentLoading = true;
        this.secPageNumber = 1;
        await this.getSecondList(comment);
        this.$set(comment, "isShow", true);
      }
    },
    async getSecondList(comment) {
      /**
       * 获取二级评论
       */
      let req = {
        objID: comment.objID,
        cmtId: comment.id,
        fstID: comment.Info[0].id, // 第一条评论的ID
        curTime: new Date().toISOString(),
        pageNumber: this.secPageNumber,
        pageSize: this.secPageSize,
        objType: "media",
      };

      try {
        let res = await this.$Api(queryCommentListlever2, req);
        this.commentParams.currentLoading = false;
        if (res.data.list && res.data.list.length > 0) {
          let commentNo = [comment.Info[0]];
          if (this.secPageNumber == 1) {
            comment.Info = commentNo.concat(res.data.list);
          } else {
            comment.Info = commentNo.concat(comment.Info.concat(res.data.list));
          }
        }
        if (!res.data.hasNext) {
          this.commentParams.currentHasNext = false;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 加载数据
    async getMediaInfoList() {
      let req = {
        mediaID: this.mediaID,
      };
      let res = await this.$Api(queryMediaInfoList, req);
      const { data } = res;
      this.isLoading = false;

      if (res && res.code == 200) {
        this.atlasData = data;
        this.currentEpisode = this.atlasData.contents[this.currentItem];
        this.atlasData.contents2 = JSON.parse(
          JSON.stringify(this.atlasData.contents)
        );
        this.msgTotle = data.countComment || 0;
        console.log(this.atlasData, this.currentEpisode);
        // let historyPostInfo = await queryHistoryId(this.atlasData.id);
        // if (historyPostInfo) {
        //  this.maxEpisode =
        //    this.$store.state.reader.maxEpisodes[this.atlasData.id];
        //  console.log(this.maxEpisode);
        //  this.scrolls(this.maxEpisode);
        //  this.activeIndex = historyPostInfo.historyIndex || 0;
        //  this.activeIndex2 = historyPostInfo.historyIndex || 0;
        // }
        setSessionItem("currentWorks", JSON.stringify(data));
        this.$store.commit("video/SET_CURRENT_WORKS", data);
      }
    },
    async getRandomInfoList() {
      let req = {
        kind: this.kind,
        pageNumber: 1,
        pageSize: 6,
      };
      let res = await this.$Api(queryRandomInfoList, req);
      if (res && res.code == 200) {
        this.randomList = res.data.MediaList;
      }
    },
    updateEpisodeState(index) {
      if (this.activeIndex2 !== undefined && this.activeIndex2 !== null) {
        this.atlasData.contents2[this.activeIndex2].mediaStatus.hasRead = true; // 已观看
      }

      this.atlasData.contents2[index].mediaStatus.hasRead = 0; // 正在观看
      console.log(index, "当前索引");
      this.activeIndex = index;
      this.activeIndex2 = index;
      this.currentItem = index;
      this.atlasData.historyIndex = this.activeIndex;
      addVideoHistory(this.atlasData);
    },
    anthology(item, index) {
      console.log(item, index);
      // let _this = this;
      // let hasPaid = this.atlasData.sellType === 1 ? this.atlasData.mediaStatus.hasPaid : item.mediaStatus.hasPaid;
      this.playEpisode(item, index);
      //vip
      // if (item.listenPermission == 0 && !hasPaid) {
      //  this.audioPause();
      //  console.log(_this.$store.getters.isVip);
      //  if (!_this.$store.getters.isVip) {
      //   this.audioPause();
      //   _this.$bus.$emit("vipPopup", {
      //    state: 1,
      //    videoType: _this.kind,
      //    id: this.atlasData.id,
      //    closeBtn: () => {
      //     _this.$bus.$emit("closeVipPopup");
      //     this.audioPause();
      //    },
      //    buySuccess: () => {
      //     hasPaid = true;
      //     this.$bus.$emit("closeVipPopup");
      //     this.currentEpisode = item;
      //     this.audioPlay();
      //     // console.log(123123)
      //    },
      //   });
      //   return;
      //  } else {
      //   this.currentEpisode = item;
      //   this.audioPlay();
      //  }
      // } else {
      //  this.currentEpisode = item;
      //  this.audioPlay();
      // }
      // //金豆收费
      // if (item.listenPermission == 1 && !hasPaid) {
      //  console.log(item, "单集", index);
      //  this.audioPause();
      //  _this.$bus.$emit("vipPopup", {
      //   state: 4,
      //   id: this.atlasData.id,
      //   videoInfo: this.atlasData,
      //   currentEpisode: this.currentEpisode,
      //   goldenNum: this.price,
      //   videoType: _this.kind,
      //   closeBtn: () => {
      //    _this.$bus.$emit("closeVipPopup");
      //    this.audioPause();
      //   },
      //   buySuccess: () => {
      //    hasPaid = true;
      //    this.$bus.$emit("closeVipPopup");
      //    this.currentEpisode = item;
      //    this.audioPlay();
      //   },
      //  });
      //  return;
      // } else {
      //  this.currentEpisode = item;
      //  this.audioPlay();
      // }
      // this.activeIndex = index;
      // this.activeIndex2 = index;
      // this.currentItem = index;
      // console.log(this.atlasData, this.currentEpisode, "+++++++++++++++++", index);
      // this.atlasData.historyIndex = this.activeIndex;
      // // console.log(JSON.stringify(this.atlasData), '---======');
      // addVideoHistory(this.atlasData);
    },
    playEpisode(item, index) {
      // let hasPaid = this.atlasData.sellType === 1 ? this.atlasData.mediaStatus.hasPaid : item.mediaStatus.hasPaid;
      let hasPaid =
        this.atlasData.mediaStatus.hasPaid || item.mediaStatus.hasPaid;
      console.log(this.atlasData.mediaStatus.hasPaid, item.mediaStatus.hasPaid);

      // VIP
      if (item.listenPermission == 0 && !hasPaid) {
        this.audioPause();
        if (!this.$store.getters.isVip) {
          this.handlePopup(1, item, this.kind, this.atlasData.id, index);
          return;
        }
      }

      // 金豆
      if (item.listenPermission == 1 && !hasPaid) {
        this.audioPause();
        this.handlePopup(
          2,
          item,
          this.kind,
          this.atlasData.id,
          this.price,
          index
        );
        return;
      }
      this.currentEpisode = item;
      this.audioPlay();
      this.updateEpisodeState(index);
    },
    handlePopup(state, item, videoType, id, goldenNum, index) {
      this.$bus.$emit("vipPopup", {
        state,
        videoType,
        id,
        videoInfo: this.atlasData,
        currentEpisode: item,
        goldenNum,
        closeBtn: () => {
          this.$bus.$emit("closeVipPopup");
          this.audioPause();
        },
        buySuccess: () => {
          console.log(this.atlasData, "类型");
          if (this.buyStatus === 1) {
            item.mediaStatus.hasPaid = true;
          } else {
            this.atlasData.mediaStatus.hasPaid = true;
          }
          // if (this.atlasData.sellType === 1) {
          //  this.atlasData.mediaStatus.hasPaid = true;
          // } else {
          //  item.mediaStatus.hasPaid = true;
          // }
          this.$bus.$emit("closeVipPopup");
          this.currentEpisode = item;
          this.audioPlay();
          this.updateEpisodeState(index);
        },
      });
    },
    onLoad() {
      this.loading = true;
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.atlasData.mediaStatus.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.atlasData.mediaStatus.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    // showInput() {
    //     console.log(123123)
    //     this.placeholder = `我怀疑你也想开车`;
    //     this.cid = null;
    //     this.currMsgItem = null;

    //     this.level = 1;
    //     this.isShowInput = true;
    //     this.$nextTick(() => {
    //         if (this.$refs.newInput) {
    //             this.$refs.newInput.focus()
    //         }
    //     })
    // },
    async getCommentList(type) {
      /**
       * 评论列表
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        curTime: new Date().toISOString(),
        objID: this.mediaID,
        objType: "media",
      };
      let res = await this.$Api(queryCommentList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.list) {
          if (type == "refresh") {
            this.commentList = res.data.list;
          } else {
            this.commentList = this.commentList.concat(res.data.list);
          }
        }
        if (
          this.pageNumber === 1 &&
          res.data.quickSearchList &&
          res.data.quickSearchList.length
        ) {
          res.data.quickSearchList.map((item) => {
            if (item.type === 2) {
              this.quickSearchObj = item;
            } else {
              // 评论对象
              const _reg = new RegExp(item.searchKeyword, "g");
              let obj = {
                id: item.id,
                content: item.title.replace(
                  _reg,
                  `<span style="color:rgba(86, 250, 246, 1)" onclick="jumpKeywords('${item.link},${item.id}')">${item.searchKeyword}</span>`
                ),
                userName: item.userName,
                userID: item.userID,
                Info: [],
                userPortrait: item.userPortrait,
                isOfficial: true,
              };
              this.commentList.splice(1, 0, obj);
            }
          });
        }
        if (this.pageNumber === 1 && this.commentList.length === 0) {
          this.isNoData = true;
        }
        if (
          !res.data.hasNext ||
          (res.data.list && res.data.list.length < req.pageSize)
        ) {
          this.finished = true;
        }
      }
    },
    jumpUserPage(item) {
      this.$router.replace({
        path: "/userHomePage",
        query: {
          uid: item.userID,
        },
      });
      this.$store.commit("user/SET_COMMENTPOP", {
        show: false,
        info: {},
      });
      if (this.$route.path == "/userHomePage") {
        this.reload();
      }
    },
    showInput() {
      let _this = this;

      /**
       * 加入了判断是否VIP
       */
      if (this.userInfo.isVip && this.userInfo.vipLevel) {
        this.level = 1;
        this.isShowInput = true;
        this.$nextTick(() => {
          if (this.$refs.newInput) {
            this.$refs.newInput.focus();
          }
        });
      } else {
        _this.$bus.$emit("vipPopup", {
          state: 1,
          closeBtn: () => {
            _this.$bus.$emit("closeVipPopup");
          },
        });
      }
    },
    closeInput() {
      this.cid = undefined;
      this.userId = undefined;
      this.msgVal = "";
      this.level = 1;
    },
    // 发送时阻止ios默认事件
    async handleMousedown(event) {
      event.preventDefault();
      this.$refs.commentInputBoxRef.blur();
      await this.sendMsg();
    },
    async sendMsg() {
      console.log(this.atlasData.contents[this.activeIndex].id);
      if (!this.commentParams.content) {
        this.$toast("请输入评论");
        return;
      }
      let req = {};
      if (this.commentParams.level == 1) {
        // 一级评论参数
        req = {
          objID: this.mediaID,
          level: 1,
          content: this.commentParams.content,
          objType: "media",
          cid: this.cid,
          sid: this.atlasData.contents[this.activeIndex].id,
        };
      } else {
        // 二级评论参数
        if (this.commentParams.toUserID) {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            rid: this.commentParams.rid,
            toUserID: this.commentParams.toUserID,
            objType: "media",
            sid: this.atlasData.contents[this.activeIndex].id,
          };
        } else {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            objType: "media",
            sid: this.atlasData.contents[this.activeIndex].id,
          };
        }
      }
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(sendV2Comment, req);
        this.$store.commit("app/SET_LOADING", false);
        this.commentParams.content = ""; // 评论输入框内容清空
        this.lfCount++; // 评论+1
        if (this.commentParams.level == 1) {
          this.isNoData = false;
          if (this.$store.getters.isVip) {
            this.commentList.unshift(res.data);
          }
        } else if (this.commentParams.level == 2) {
          this.commentList = this.commentList.map((item) => {
            if (item.id == this.commentParams.currentFirstItem.id) {
              item.isShow = true;
              if (this.$store.getters.isVip) {
                item.Info.unshift(res.data);
              }
            }
            return item;
          });
        }
        this.clearReply();
        this.$toast("评论成功");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("评论失败，请稍后再试");
      }
    },
    clearReply() {
      this.commentParams.currentFirstItem = {};
      this.commentParams.objID = "";
      this.commentParams.cid = "";
      this.commentParams.userID = "";
      this.commentParams.level = 1;
      this.placeholder = `我怀疑你也想开车`;
    },
    // 回复-显示输入框
    replyMsg(item) {
      this.placeholder = `回复 @${item.userName}`;
      this.cid = item.id;
      this.userId = item.userId;
      this.level = 2;
      this.isShowInput = true;
      this.currMsgItem = item;
      this.$nextTick(() => {
        if (this.$refs.newInput) {
          this.$refs.newInput.focus();
        }
      });
    },
    async loadMoreSubComment(comment) {
      /**
       * 获取更多二级评论
       * @type {boolean}
       */
      this.commentParams.currentLoading = true;
      this.secPageNumber++;
      await this.getSecondList(comment);
    },
    reply(item, parentItem) {
      /**
       * 二级评论输入框显示事件
       */
      if (parentItem != null) {
        this.commentParams.currentFirstItem = parentItem;
        this.commentParams.rid = item.id;
        this.commentParams.toUserID = item.userID;
        this.commentParams.cid = parentItem.id;
      } else {
        this.commentParams.currentFirstItem = item;
        this.commentParams.rid = "";
        this.commentParams.toUserID = "";
        this.commentParams.cid = item.id;
      }
      this.commentParams.objID = item.objID;
      this.commentParams.userID = item.userID;
      this.commentParams.level = 2;
      this.placeholder = `回复@${item.userName}`;
      this.$refs.commentInputBoxRef.focus();
    },
    tagJump(index, itemTag) {
      let tagInfo = {
        id: this.atlasData.tags[index],
        name: itemTag.name,
        isAcg: 1,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagMore") {
        this.$router.replace({
          path: "/tagMore",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.atlasData.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagMore",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.atlasData.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    jumpDetails(item) {
      this.$router.push({
        path: "/commentDetails",
        query: {
          msgInfo: this.encodeBase64(encodeURI(JSON.stringify(item))),
        },
      });
      // this.commentClose();
    },
    // 点赞
    async likeComment(item) {
      let req = {
        objID: item.id,
        type: "comment",
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(
          item.isLike ? thumbsDownComment : thumbsUpComment,
          req
        );
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          if (item.isLike) {
            item.likeCount = item.likeCount - 1 || 0;
          } else {
            item.likeCount = item.likeCount + 1;
          }
          item.isLike = !item.isLike;

          Toast(item.isLike ? "点赞成功" : "取消点赞");
        } else {
          Toast(res.tip || "操作失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audioBookDetails {
  height: 100%;
  background: #f1f1f1;
  overflow-x: hidden;
  position: relative;
  .tabBars {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    //height: 60px;
    padding: 0 12px 34px 0;
    width: 100%;
    z-index: 999;
    .comments {
      height: 36px;
      display: flex;
      justify-content: space-between;
      padding-top: 13px;
      padding-right: 10px;
      color: rgb(153, 153, 153);
      font-size: 15px;
      .Inputs {
        border-radius: 31px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        /deep/ .van-field__body {
          background: rgba(245, 245, 245, 1);
          box-shadow: 2px 2px 4px 0px rgba(174, 174, 192, 0.2) inset;
          padding: 6px 17px;
          border-radius: 8px;
        }
      }
      .sendBtn {
        display: flex;
        align-items: center;
        img {
          margin-left: 12px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .head {
    //position: relative;
    height: 44px;
    background: #fff;
    width: 100%;

    .headBar {
      display: flex;
      justify-content: space-between;
      height: 44px;
      align-items: center;
      z-index: 999;
      margin-top: 2px;
      .leftIcon {
        padding: 0 16px;

        .navArrow {
          width: 20px;
          height: 20px;
        }
      }
      .worksTitle {
        font-size: 18px;
        color: rgb(0, 0, 0);
        font-weight: bold;
      }
    }

    .btnGroup {
      .collectionBtn {
        margin-right: 12px;

        .collect {
          width: 24px;
          height: 24px;
        }

        img {
          width: 60px;
          height: 24px;
        }
      }
    }
  }

  .tabBar {
    background: rgb(240, 240, 244);
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 2;

    .comments {
      height: 59px;
      display: flex;
      justify-content: space-between;
      padding: 19px 24px;
      color: rgb(153, 153, 153);
      //font-weight: bold;
      font-size: 15px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .placeholder {
      height: 34px;
    }
  }

  .content {
    height: calc(100% - 60px);
    overflow-y: auto;
    position: relative;
  }

  .bgBox {
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .headBg {
      width: 321px;
      height: 427.67px;
      border-radius: 30px;
      border: 6px solid #fff;
      //-webkit-filter: blur(2px);
      //filter: blur(2px);
      position: relative;
      /deep/ .van-image__img {
        border-radius: 30px;
      }
    }
    .headBg:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 42.22%,
        #f5f5f5 94.18%
      );
      z-index: 2;
    }

    .contentInfo {
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%;
      height: 100px;
      z-index: 9;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 4.22%,
        #f5f5f5 33%
      );
    }

    .worksContent {
      .action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.02);
        padding: 0 26px 18px;
        box-sizing: border-box;
        z-index: 11;
        .palyIcon {
          width: 20px;
          height: 20px;
        }
        .play {
          width: 50px;
          height: 50px;
        }
      }
      .controlBar {
        align-items: center;
        flex-shrink: 0;
        width: calc(100% - 10 * 2);
        height: 24px;
        margin: 0 22px 10px;
        position: relative;

        .progress {
          width: 171px !important;
          height: 8px !important;
          margin: 0 2px 0 6px;
          .custom-button {
            vertical-align: middle;
            width: 18px;
            height: 18px;
            padding-bottom: 2px;
          }
        }

        svg {
          width: 24px;
          height: 24px;
        }

        .progress {
          height: 4px;
          // background: #fff;
          border-radius: 8px;
          position: relative;
          width: 251px;
        }
      }
    }
  }

  .directory {
    //height: 97px;
    margin-top: 82px;

    .directoryBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;
      padding: 0 12px;
      color: rgb(0, 0, 0);

      .directoryBarTitle {
        font-size: 14px;
        font-weight: 900;
        color: #333333;
      }

      .directoryBtn {
        font-size: 12px;
        color: #777777;

        img {
          width: 6.9px;
          height: 8.6px;
          margin-left: 8.1px;
        }
      }
    }
    .anthology {
      display: flex;
      margin-top: 12px;
      // overflow-x: auto;
      overflow-y: hidden;
      padding-right: 12px;

      .activeItem {
        background-image: linear-gradient(#f5f5f5, #f5f5f5),
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 100%,
            rgba(255, 255, 255, 1) 100%
          ) !important;
        div:nth-child(2) {
          background: #fff;
        }
      }

      .anthologyItem {
        //-webkit-flex: 1 0 auto;
        margin-left: 12px;
        width: 50px;
        height: 67px;
        line-height: 26px;
        text-align: center;
        border: double 3px transparent;
        border-radius: 8px;
        background-image: linear-gradient(white, white),
          linear-gradient(
            135deg,
            rgba(0, 0, 0, 0.05) 0%,
            rgba(216, 216, 216, 0.05) 100%
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .anthologyTxt {
          display: flex;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;
          span {
            font-size: 18px;
            font-weight: 900;
            color: rgba(176, 159, 217, 1);
          }
        }

        .vipLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f5f5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
            width: 18px;
            height: 8px;
          }

          //span {
          //  //background-image: linear-gradient(to right, #f3cd64, #fde39c);
          //  background: #F5F5F5;
          //  border-radius: 8px;
          //  font-size: 10px;
          //  width: 31px;
          //  height: 19px;
          //  color: #814CFF;
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //}
        }

        .goldenLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f5f5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
            width: 18px;
            height: 8px;
          }
          //display: flex;
          //justify-content: flex-end;
          //width: 60px;
          //
          //span {
          //  background-image: linear-gradient(
          //    to right,
          //    rgb(255, 57, 87),
          //    rgb(255, 89, 34)
          //  );
          //  font-size: 6px;
          //  width: 24px;
          //  height: 12px;
          //  color: rgb(255, 255, 255);
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //  border-radius: 3px;
          //}
        }

        .freeLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f5f5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
            width: 18px;
            height: 8px;
          }
          //display: flex;
          //justify-content: flex-end;
          //width: 60px;
          //
          //span {
          //  background-image: linear-gradient(
          //    to right,
          //    rgb(87, 139, 255),
          //    rgb(51, 255, 227)
          //  );
          //  font-size: 6px;
          //  width: 24px;
          //  height: 12px;
          //  color: rgb(255, 255, 255);
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //  border-radius: 3px;
          //}
        }

        .activePlay {
          position: relative;
          top: -22px;
          left: 7px;

          img {
            width: 13px;
            height: 8px;
          }
        }
      }
    }

    .startReading {
      margin-top: 31px;
      display: flex;
      justify-content: center;
      align-items: center;

      .startReadingBtn {
        width: 333px;
        height: 56px;
        border-radius: 22px;
        background: #f0f0f3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: rgba(176, 159, 217, 1);
        font-weight: 900;
        box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
      }
    }
  }
  .lines {
    width: 92%;
    height: 1px;
    margin: 26px 16px 0 16px;
    background: #e6e6e6;
  }
  .recommendVideo {
    margin-top: 36px;
    padding: 0 12px;

    .recommendTitle {
      font-size: 18px;
      color: rgb(0, 0, 0);
      font-weight: bold;
      margin-bottom: 9px;
    }
  }

  .introductionContent {
    padding: 0 12px;

    .introductionBar {
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .introductionTitle {
      font-size: 16px;
      color: rgb(0, 0, 0);
      font-weight: 500;
      margin-top: 16px;
    }

    .introductionTag {
      margin: 8px 0 16px 0;
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;

      span {
        margin-top: 8px;
        margin-right: 12px;
        background: rgba(148, 214, 218, 0.3);
        border-radius: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 10px;
        line-height: 16.8px;
      }
    }

    .introductionDetails {
      color: rgb(0, 0, 0);
      font-size: 12px;
    }
  }

  .directoryContent {
    .directoryBar {
      padding: 0 12px;
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 18px;
        color: rgb(0, 0, 0);
        font-weight: bold;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .anthologyContent {
      margin: 24px auto;

      .box {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 12px;
      }

      .activeItem {
        background-image: linear-gradient(#f5f5f5, #f5f5f5),
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 100%,
            rgba(255, 255, 255, 1) 100%
          ) !important;
        div:nth-child(2) {
          background: #fff;
        }
      }

      .anthologyItem {
        //-webkit-flex: 1 0 auto;
        margin-bottom: 15px;
        width: 341px;
        height: 40px;
        line-height: 26px;
        text-align: center;
        border: double 3px transparent;
        border-radius: 8px;
        background-image: linear-gradient(white, white),
          linear-gradient(
            135deg,
            rgba(0, 0, 0, 0.05) 0%,
            rgba(216, 216, 216, 0.05) 100%
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .isWatch {
          background: transparent !important;
          font-size: 13px;
          color: #777777;
          padding-right: 14px;
        }

        .anthologyTxt {
          display: flex;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;
          margin-left: 14px;
          span {
            font-size: 18px;
            font-weight: 900;
            color: rgba(176, 159, 217, 1);
            padding-left: 14px;
          }
        }

        .vipLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f5f5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
            width: 18px;
            height: 8px;
          }
        }

        .goldenLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f5f5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
            width: 18px;
            height: 8px;
          }
        }

        .freeLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f5f5f5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
            width: 18px;
            height: 8px;
          }
        }

        .activePlay {
          position: relative;
          top: -22px;
          left: 7px;

          img {
            width: 13px;
            height: 8px;
          }
        }
      }
    }
  }

  .separate {
    background: rgb(230, 230, 230);
    height: 1px;
  }

  .comments {
    .commentsContent {
      //height: calc(100vh - 211px - 51px - 92px);
      color: rgb(0, 0, 0);
      //height: 100%;
      //overflow-y: auto;

      .commentsHead {
        font-size: 16px;
        font-weight: 500;
        margin-top: 18px;
        padding-left: 12px;
        .comTotle {
          padding-left: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #94d6da;
        }
      }

      .qiuckSearchBox {
        color: #333333;
        font-weight: 600;
        font-size: 14px;
        margin: 3px 10px 0 10px;
        white-space: nowrap;
        position: relative;

        /deep/ .van-notice-bar__content {
          color: #f92b6c !important;
          font-weight: 600 !important;
        }

        /deep/ .van-notice-bar {
          width: 100%;
          padding: 0;
        }

        .qiuckSearchIcon {
          height: 10px;
          width: 10px;
          position: absolute;
          right: -10px;
          top: 2px;
        }
      }

      .commentsContentList {
        padding: 18px 12px;
        .commentBox {
          margin-bottom: 18px;
          margin-top: 18px;
          border-bottom: solid rgb(229, 229, 229) 1px;

          .commentInfo {
            display: flex;
            width: 100%;

            .godMsg {
              position: absolute;
              right: 55.8px;
              width: 60.2px;
              height: 60.2px;
              z-index: 10;
            }

            .avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              margin-right: 12px;
              flex-shrink: 0;
            }

            .info {
              width: calc(100vw - 77px);
              flex-shrink: 0;
              color: rgb(51, 51, 51);

              .name {
                color: #333333;
                font-size: 14px;
                display: flex;
                align-items: center;

                .hotImg {
                  width: 44px;
                  height: 22px;
                  vertical-align: middle;
                }

                span {
                  margin-right: 4px;
                }

                svg {
                  width: 10px;
                  height: 10px;
                }
              }

              .msgContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zanBox {
                  position: absolute;
                  right: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  z-index: 20;

                  .zanIcon {
                    height: 22px;
                    width: 22px;
                    margin-bottom: 5px;
                  }

                  .zanNumber {
                    font-size: 12px;
                  }
                }
              }

              .text {
                //width: 300px;
                //text-overflow: ellipsis;
                //word-wrap: break-word;
                //white-space: pre-wrap;

                //width: 100%;
                //overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;

                font-size: 14px;
                //margin: 4px 0 7px;
                margin-top: 5px;
                color: rgb(51, 51, 51);
              }

              .address {
                //font-size: 10px;
                //color: rgb(153, 153, 153);
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .createdAt {
                  font-size: 12px;
                  color: rgb(153, 153, 153);
                }

                //.btn {
                //  font-size: 10px;
                //  color: rgb(255, 255, 255);
                //  margin-left: 6px;
                //}

                .btnBox {
                  display: flex;
                  align-items: center;

                  .icon {
                    width: 20px;
                    height: 20px;
                  }

                  .community_info {
                    width: 14px;
                    height: 14px;
                  }

                  .like {
                    margin-left: 18px;
                  }
                }
              }
            }

            .childInfo {
              width: calc(100vw - 129px);
              flex-shrink: 0;
              color: rgb(102, 102, 102);

              .name {
                color: rgb(153, 153, 153);
                font-size: 12px;
                display: flex;
                align-items: center;

                span {
                  margin-right: 4px;
                }

                svg {
                  width: 10px;
                  height: 10px;
                }

                .rightCommIcon {
                  width: 24px;
                  height: 18px;
                  margin-left: 2px;
                  margin-right: 3px;
                }
              }

              .msgContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zanBox {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  z-index: 20;

                  .zanIcon {
                    height: 22px;
                    width: 22px;
                    margin-bottom: 5px;
                  }

                  .zanNumber {
                    font-size: 12px;
                  }
                }
              }

              .text {
                //width: 300px;
                //text-overflow: ellipsis;
                //word-wrap: break-word;
                //white-space: pre-wrap;

                //width: 100%;
                //overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;

                font-size: 14px;
                //margin: 4px 0 7px;
                margin-top: 5px;
                color: rgb(51, 51, 51);
              }

              .address {
                font-size: 10px;
                color: rgb(153, 153, 153);
                margin-top: 8px;

                .btn {
                  font-size: 10px;
                  color: rgb(255, 255, 255);
                  margin-left: 6px;
                }
              }
            }
          }

          .expand-box {
            font-size: 12px;
            color: rgb(153, 153, 153);
            font-weight: 300;
            margin: 8px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;

            > svg {
              height: 12px;
              width: 12px;
              margin-left: 5px;
            }
          }

          // 旋转180度
          .rotate-target {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }

          // 旋转0度
          .rotate-burden-target {
            transform: rotate(0deg);
            transition: transform 0.3s;
          }
        }
      }
    }
  }

  .inputPop {
    .inputBox {
      height: 59px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newInput {
        width: 273px;
        height: 31px;
        border-radius: 31px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #e6e6e6;

        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        ::placeholder {
          color: #999;
        }
      }

      .sendBtn {
        width: 66px;
        height: 33px;
        border-radius: 33px;
        font-size: 15px;
        color: #fff;
        background: #757494;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .activeBtn {
        color: #fff;
        background: #7145e7;
      }
    }
  }

  .nodata {
    height: calc(100vh - 103px);
    // height: calc(100% - 92px);
    width: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.rate-popup {
  width: 100%;
  height: 220px;
  background: #fff;
  padding: 33px 0;
  border-radius: 20px 20px 0 0;
  .title {
    color: #4e4e4e;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .picker {
    /deep/ .van-picker__toolbar {
      display: none;
    }
    /deep/ .van-picker-column__item--selected {
      color: #c5adff;
      font-size: 18px;
      font-weight: 900;
      line-height: 24px;
      text-align: center;
    }
  }
}
.time-popup {
  width: 100%;
  height: 200px;
  background: #fff;
  padding: 18px 0;
  border-radius: 8px 8px 0 0;
  .option-box {
    width: 100%;
    height: 192px;
    border-radius: 16px;
    background: #fff;
    .header {
      align-items: center;
      color: #b09fd9;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      padding: 0 10px;
      .title {
        color: #333333;
        font-size: 16px;
        font-weight: 900;
        line-height: 22px;
      }
    }
    .top {
      padding: 18px 10px;
      .list {
        margin: 20px auto;
        .item {
          border-radius: 8px;
          background: #fff;
          height: 40px;
          color: #b09fd9;
          font-size: 12px;
          font-weight: 600;
          border: 2px solid #f5f5f5;
          text-align: center;
          line-height: 40px;
          padding: 0 14px;
          white-space: nowrap;
          &.active {
            background-color: #f5f5f5;
            color: #999999;
          }
        }
      }
    }
    .bottom {
      margin-top: 34px;
      display: flex;
      justify-content: center;
      /deep/ .van-checkbox__label {
        color: #999999;
      }
    }
  }
  .picker-box {
    .picker {
      width: 100%;
      height: 192px;
      border-radius: 8px;
      background: #fff;
      overflow: hidden;
      /deep/ .van-picker__toolbar {
        display: none;
      }
      /deep/ .van-picker__columns {
        // height: 192px !important;
        .van-picker-column:first-child {
          .van-picker-column__wrapper {
            .van-picker-column__item--selected::after {
              content: "小时";
              position: fixed;
              right: 45px;
              width: 40px;
              height: 22px;
              color: #00000066;
              font-size: 13px;
              white-space: nowrap;
            }
          }
        }
        .van-picker-column:nth-child(2) {
          .van-picker-column__wrapper {
            .van-picker-column__item--selected::after {
              content: "分钟";
              position: fixed;
              right: 45px;
              width: 40px;
              height: 22px;
              color: #00000066;
              font-size: 13px;
              white-space: nowrap;
            }
          }
        }
      }
      /deep/ .van-picker-column__item--selected {
        color: #000;
        font-size: 18px;
        font-weight: 900;
        line-height: 24px;
        text-align: center;
      }
      ///deep/ .van-picker-column__item--selected::after {
      // content: '分钟';
      // position: fixed;
      // right: 45px;
      // width: 40px;
      // height: 22px;
      // color: #00000066;
      // font-size: 13px;
      // white-space: nowrap;
      //}
    }
    .btns {
      padding: 0 10px;
      .btn {
        color: #999999;

        font-size: 16px;
        font-weight: 500;
        &.confirm {
          color: #333333;
        }
      }
    }
  }
}
</style>
